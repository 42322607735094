import React from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import Project from "../components/Project";
import GoToTop from "../components/GoToTop";
import { Helmet } from 'react-helmet';

export default function GraphicDesign() {
  return (
    <React.Fragment>
        <Helmet>
        <title>Graphic Design</title>
      </Helmet>
      <section className="section--project-hero web">
        <div className="project--hero-content">
          <Navigation />

          <div className="row">
            <div className="large-12 columns medium-12 small-10 large-uncentered small-centered">
              <div className="project--intro">
                <h1>Graphic Design</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Project
        projectName="Brainscape Folder"
        projectDescription="Graphic design of a brochure containing information about the different Brainscape services."
        imageUrl="/assets/images/brainscape-folder_640.png"
        imageUrlSmall="/assets/images/brainscape-folder_320.png"
        altText="Brainscape folder preview"
        bgColour="orange"
      />

      <Project
        imgRight="true"
        projectName="Wellness Kliniek Booklet"
        projectDescription="Booklet for future patients interested in breast augmentation. Made available as a PDF, digital download (.epub) and printed version in 4 languages."
        imageUrl="/assets/images/wk-booklet_640.png"
        imageUrlSmall="/assets/images/wk-booklet_320.png"
        altText="Wellness Kliniek breast augmentation booklet preview"
        bgColour="purple"
      />
      <Project
        projectName="Expo Gamma Catalogue"
        projectDescription="Expo Gamma product catalogue showcasing all rental products. Available as online scatalogue and printed version."
        imageUrl="/assets/images/expogamma_640.png"
        imageUrlSmall="/assets/images/expogamma_320.png"
        altText="Expogamma catalogue preview"
        bgColour="green"
      />

      <Project
        imgRight="true"
        projectName="Laborama Banners"
        projectDescription="Banner design for various Laborama trade fairs."
        imageUrl="/assets/images/lab-misc_640.png"
        imageUrlSmall="/assets/images/lab-misc_320.png"
        altText="Laborama Banners preview"
        bgColour="tealgreen"
      />

      <Project
        projectName="Wellness Kliniek imagery"
        projectDescription="Images for use on social media and in online advertising campaigns."
        imageUrl="/assets/images/wk-misc_640.png"
        imageUrlSmall="/assets/images/wk-misc_320.png"
        altText="Wellness Kliniek imagery preview"
        bgColour="purple"
      />

      <GoToTop />
      <Footer />
    </React.Fragment>
  );
}
