import React from "react";
import { Link } from "react-router-dom";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import Greeting from "../components/Greeting";
import { Helmet } from 'react-helmet';


export default function Home() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Portfolio</title>
      </Helmet>
      <div>
        <section className="section--home-hero">
          <div className="home--hero-content">
            <Navigation />
            <div className="row">
              <div className="large-6 columns">
                <h1 className="greeting">
                <Greeting />
                </h1>
                <div className="intro">
                  <h2>
                    I'm Natalie - a UX Designer, Web Designer &amp; Graphic
                    Designer
                  </h2>
                  <p>
                    Curious about the projects I have worked on alone or
                    together with my colleagues in recent years? Here you can find a small selection.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section--project-list">
          <div className="row">
            <div className="large-12 columns">
              <div className="project-list">
                <div className="project case-study">
                  <div className="bg">
                    <div className="project-list--content">
                      <div>
                        <div className="project-title">
                          UX Research and Design of an airline website
                        </div>
                        <Link
                          to="/projects/ux-case-study-airline"
                          className="button hollow grey-500"
                        >
                          See case study
                        </Link>
                      </div>
                      <div className="project-image">
                        <img src="/assets/images/ux_mjn_640.png"
                        srcSet="/assets/images/ux_mjn.png 320w, 
                        /assets/images/ux_mjn_640.png 640w"
                        sizes="(max-width: 40em) 100vw, 
                              (max-width: 64em) 100vw, 
                              (min-width: 64.063em) 100vw, 640px"
                        alt="UX Research preview of airline project" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="project case-study-ui">
                  <div className="bg">
                    <div className="project-list--content">
                      <div>
                        <div className="project-title">
                          UI Design of a banking application
                        </div>
                        <Link
                          to="/projects/ui-case-study-banking-app"
                          className="button hollow grey-500"
                        >
                          See case study
                        </Link>
                      </div>
                      <div className="project-image">
                        <img src="/assets/images/ui_bank_640.png"
                        srcSet="/assets/images/ui_bank.png 320w, 
                        /assets/images/ui_bank_640.png 640w"
                        sizes="(max-width: 40em) 100vw, 
                              (max-width: 64em) 100vw, 
                              (min-width: 64.063em) 100vw, 640px"
                        alt="UX Research preview of airline project" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="project web">
                  <div className="bg">
                    <div className="project-list--content">
                      <div>
                        <div className="project-title">Web Projects</div>
                        <Link
                          to="/projects/web-projects"
                          className="button hollow grey-500"
                        >
                          See projects
                        </Link>
                      </div>
                      <div className="project-image">
                        <img src="/assets/images/brainscape_640.png"
                        srcSet="/assets/images/brainscape_320.png 320w, 
                        /assets/images/brainscape_640.png 640w"
                        sizes="(max-width: 40em) 100vw, 
                              (max-width: 64em) 100vw, 
                              (min-width: 64.063em) 100vw, 640px"
                        alt="Web Projects Preview of Brainscape website" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="project design">
                  <div className="bg">
                    <div className="project-list--content">
                      <div>
                        <div className="project-title">Graphic Design</div>
                        <Link
                          to="/projects/graphic-design"
                          className="button hollow grey-500"
                        >
                          See projects
                        </Link>
                      </div>
                      <div className="project-image">
                        <img src="/assets/images/expogamma_640.png"
                        srcSet="/assets/images/expogamma_320.png 320w, 
                        /assets/images/expogamma_640.png 640w"
                        sizes="(max-width: 40em) 100vw, 
                              (max-width: 64em) 100vw, 
                              (min-width: 64.063em) 100vw, 640px"
                        alt="Graphic Design Preview of Expo Gamma catalogue" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </React.Fragment>
  );
}
